import React from "react";
import Layout from "../../components/Layout";
import { Link, graphql } from "gatsby";
import kebabCase from "lodash/kebabCase";

const Categories = ({ data }) => {
  const {
    allMdx: { group: categoryList },
  } = data;
  return (
    <Layout>
      <h1>Categories</h1>
      <ul>
        {categoryList.map((category) => (
          <li key={category.fieldValue}>
            <Link to={`/blog/categories/${kebabCase(category.fieldValue)}/`}>
              {category.fieldValue} ({category.totalCount})
            </Link>
          </li>
        ))}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  {
    allMdx {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
    }
  }
`;

export default Categories;
